* {
  box-sizing: border-box;
}

html,
body {
  max-width: 100vw;
}

a {
  color: inherit;
  text-decoration: none;
}
